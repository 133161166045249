/* App.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes dancing {
  0%, 100% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(10deg) scale(1.2);
  }
  50% {
    transform: rotate(-10deg) scale(0.8);
  }
  75% {
    transform: rotate(10deg) scale(1.2);
  }
}

.dancingImage {
  animation: dancing 2s infinite;
  width: 200px;  
  height: 200px; 
  border-radius: 20px; 
  margin-bottom:20px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.bouncingImage {
  animation: bounce 2s infinite;
  width: 200px;  
  height: 200px; 
  border-radius: 20px; 
}
